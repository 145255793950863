<template>
  <b-overlay
    :show="$apollo.loading"
    rounded="sm"
    spinner-variant="primary"
  >
    <!-- search input -->
    <div class="custom-search d-flex justify-content-start">
      <b-form-group
        label="Search"
        label-size="sm"
      >
        <b-form-input
          v-model="searchTerm"
          class="d-inline-block mr-1"
          placeholder="Search students"
          type="text"
        />
      </b-form-group>
    </div>

    <!-- table -->
    <vue-good-table
      :columns="columns"
      :pagination-options="{
        enabled: true,
        perPage:pageLength
      }"
      :rows="rows"
      :search-options="{
        enabled: true,
        externalQuery: searchTerm }"
    >
      <template
        slot="table-row"
        slot-scope="props"
      >

        <!-- Column: Common -->
        <span class="d-flex justify-content-between">
          <span v-if="props.column.field == 'Actions'">
            <!-- <b-button
              size="sm"
              variant="outline-primary"
              :to="{name: 'view-programs-manager', params: {pid: $route.params.id, aid: props.row.id}}"
            >
              <span>Manage</span>
            </b-button>
            <b-button
              size="sm"
              variant="outline-primary"
              :to="{name: 'view-growth', params: {pid: $route.params.id, aid: props.row.id}}"
            >
              <span>Growth</span>
            </b-button> -->
            <b-button
              size="sm"
              variant="outline-primary"
              class="ml-50"
              :href="`/mentor-portal/programs/select/${$route.params.id}/${$route.params.pid}/${props.row.sid}/${$route.params.sname}#Exercises`"
            >
              <span>Exercises</span>
            </b-button>
            <b-button
              size="sm"
              variant="outline-primary"
              class="ml-50"
              :href="`/mentor-portal/programs/select/${$route.params.id}/${$route.params.pid}/${props.row.sid}/${$route.params.sname}#Milestones`"
            >
              <span>Milestones</span>
            </b-button>
            <!-- <b-button
              size="sm"
              variant="outline-primary"
              class="ml-50"
              :to="{name: 'view-feedbacks', params: {pid: $route.params.id, aid: props.row.id}}"
            >
              <span>Feedbacks</span>
            </b-button>
            <b-button
              size="sm"
              variant="outline-primary"
              class="ml-50"
              :to="{name: 'view-requests', params: {pid: $route.params.id, aid: props.row.id}}"
            >
              <span>Requests</span>
            </b-button>
            <b-button
              size="sm"
              variant="outline-primary"
              class="ml-50"
              :to="{name: 'view-emeets', params: {pid: $route.params.id, aid: props.row.id}}"
            >
              <span>E-Meets</span>
            </b-button> -->
          </span>
          <span v-if="props.column.field == 'category'">
            <b-badge :variant="props.formattedRow[props.column.field] == 'Assigned' ? 'primary' : 'secondary'">
              {{ props.formattedRow[props.column.field] }}
            </b-badge>
          </span>
          <span v-else>
            {{ props.formattedRow[props.column.field] }}
          </span>
        </span>
      </template>

      <!-- pagination -->
      <template
        slot="pagination-bottom"
        slot-scope="props"
      >
        <div class="d-flex justify-content-between flex-wrap">
          <div class="d-flex align-items-center mb-0 mt-1">
            <span class="text-nowrap ">
              Showing 1 to
            </span>
            <b-form-select
              v-model="pageLength"
              :options="['50','75','100']"
              class="mx-1"
              @input="(value)=>props.perPageChanged({currentPerPage:value})"
            />
            <span class="text-nowrap"> of {{ props.total }} entries </span>
          </div>
          <div>
            <b-pagination
              :per-page="pageLength"
              :total-rows="props.total"
              :value="1"
              align="right"
              class="mt-1 mb-0"
              first-number
              last-number
              next-class="next-item"
              prev-class="prev-item"
              @input="(value)=>props.pageChanged({currentPage:value})"
            >
              <template #prev-text>
                <feather-icon
                  icon="ChevronLeftIcon"
                  size="18"
                />
              </template>
              <template #next-text>
                <feather-icon
                  icon="ChevronRightIcon"
                  size="18"
                />
              </template>
            </b-pagination>
          </div>
        </div>
      </template>
    </vue-good-table>
  </b-overlay>
</template>
<script>
import {
  BButton, BFormGroup, BFormInput, BFormSelect, BPagination, BBadge,
} from 'bootstrap-vue'
import gql from 'graphql-tag'
import { VueGoodTable } from 'vue-good-table'
// import { getUserData } from '@/auth/utils'

export default {
  components: {

    BFormInput,
    BFormGroup,
    VueGoodTable,
    BPagination,
    BFormSelect,
    BButton,
    BBadge,
  },
  data() {
    return {
      searchTerm: '',
      rounds: [
        { title: null },
      ],
      pageLength: 50,
      filter: {
        status: 'Current',
      },
      columns: [

        {
          label: 'Name',
          field(row) {
            return row.title
          },
        },
        {
          label: 'Category',
          field: 'category',
          filterOptions: {
            enabled: true, // enable filter for this column
            customFilter: true, // use custom filter component
            placeholder: 'All', // placeholder for search input
            filterDropdownItems: ['Assigned', 'Program'],
          },
        },
        {
          label: 'Actions',
          field: 'Actions',
        },
      ],
      rows: [],
    }
  },
  apollo: {
    rows: {
      query() {
        return gql`
        {
          programs_basicinfo(where: {id: {_eq: ${this.$route.params.id}}}) {
            programs_startupparticipants {
              id
              programs_applicantstable {
                users_organizationtable {
                  title
                }
              }
              programs_startupassignmenttables(where: {partner_id: {_eq: ${this.$route.params.pid}}}, order_by: {id: desc}) {
                programs_startupparticipant {
                  id
                }
              }
            }
            programs_partnerstables(where: {id: {_eq: ${this.$route.params.pid}}}) {
              is_super
              is_jury
            }
          }
        }`
      },
      update(data) {
        const temp = []
        if (data.programs_basicinfo[0].programs_partnerstables[0].is_super) {
          data.programs_basicinfo[0].programs_startupparticipants.forEach(startup => {
            temp.push({
              sid: startup.id,
              title: startup.programs_applicantstable.users_organizationtable.title,
              category: startup.programs_startupassignmenttables.length > 0 ? 'Assigned' : 'Program',
            })
          })
        } else {
          data.programs_basicinfo[0].programs_startupparticipants.forEach(startup => {
            if (startup.programs_startupassignmenttables.length > 0) {
              temp.push({
                sid: startup.id,
                title: startup.programs_applicantstable.users_organizationtable.title,
                category: 'Assigned',
              })
            }
          })
        }
        return temp
      },
    },
  },
}
</script>
